document.addEventListener('turbolinks:load', () => {
  const navbar = document.getElementById('header')
  const body = document.querySelector('body')
  let lastKnownScrollPosition = 0

  const hideNav = () => {
    navbar.classList.remove('background')
    navbar.classList.add('hidden')
  }

  const showNav = () => {
    navbar.classList.remove('hidden')
    navbar.classList.add('background')
  }

  const resetNav = () => {
    navbar.classList.remove('hidden')
    navbar.classList.remove('background')
  }

  const toggleNav = () => {
    let topScroll = body.scrollTop
    console.log('------------------------------')
    console.log(lastKnownScrollPosition)
    console.log(topScroll)
    if (topScroll > 150) {
      if (lastKnownScrollPosition > topScroll) {
        showNav()
      } else {
        hideNav()
      }
    }

    if (topScroll < 30) {
      resetNav()
    }

    lastKnownScrollPosition = body.scrollTop
  }

  const throttle = wait => {
    let time = Date.now()
    return () => {
      if ((time + wait - Date.now()) < 0) {
        toggleNav()
        time = Date.now()
      }
    }
  }

  if (navbar) {
    body.addEventListener('scroll', throttle(100))
  }
})
