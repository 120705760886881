import { csrfToken } from "@rails/ujs"

document.addEventListener('turbolinks:load', () => {
    let contactForm = document.getElementById('contact-form')

    if (contactForm) {
        contactForm.addEventListener('submit', (event) => {
            event.preventDefault()

            let postBody = buildPostBody()
            submitForm(event, postBody)
        })

        const messageSuccess = () => {
            const formContainer = document.getElementById('contact-form')
            formContainer.innerHTML = "<p>Mensaje recibido! Nos pondremos en contacto contigo pronto.</p>"
        }

        const messageFail = (errors) => {
            const formContainer = document.getElementById('contact-form')
            const submitButton = document.getElementById('submit-button')
            console.log(errors)
            for (const error in errors) {
                let message = `${errors[error]}`
                let par = document.createElement("p")
                par.innerText = message
                formContainer.insertBefore(par, submitButton)
            }
            submitButton.disabled = false
        }

        const buildPostBody = () => {
            const nameInput = document.getElementById('name-input')
            const emailInput = document.getElementById('email-input')
            const phoneInput = document.getElementById('phone-input')
            const contentInput = document.getElementById('name-input')

            return {
                'message': {
                    'name': nameInput.value,
                    'email': emailInput.value,
                    'phone': phoneInput.value,
                    'content': contentInput.value
                }
            }
        }

        const submitForm = (event, postBody) => {
            fetch("/messages", {
                method: "POST",
                headers: {
                    "X-CSRF-Token": csrfToken(),
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(postBody)
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data["errors"])
                    data["errors"] ? messageFail(data["errors"]) : messageSuccess()
                });
        }
    }
})


