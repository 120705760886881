import ScrollMagic from 'scrollmagic'

document.addEventListener('turbolinks:load', () => {
  let controller = new ScrollMagic.Controller()
  const scenes = ['.o-quote', '.o-what-we-do', '.o-requirements-list', '.o-about', '.o-sales']

  const options = scene => {
    return {
      triggerElement: scene,
      triggerHook: 0.9,
      offset: 50
    }
  }

  const setScene = scene => {
    new ScrollMagic.Scene(options(scene)).setClassToggle(scene, 'visible').addTo(controller)
  }

  scenes.forEach(scene => {
    if (document.querySelector(scene)) {
      setScene(scene)
    }
  })
})


