import Glide from '@glidejs/glide'

const elementDisplayed = (element) => {
  return (window.getComputedStyle(element).display !== 'none')
}

const addGlide = (element) => {
  let glideContainer = element.getElementsByClassName('glide-container')
  glideContainer[0].classList.add('glide')

  new Glide('.glide', { type: 'carousel', startAt: 0 }).mount()
}

document.addEventListener("turbolinks:load", () => {
  let mobileCarousel = document.getElementById('mobile-carousel')
  let desktopCarousel = document.getElementById('desktop-carousel')

  if (desktopCarousel) {
    if (elementDisplayed(desktopCarousel)) {
      addGlide(desktopCarousel)
    }
  }

  if (mobileCarousel) {
    if (elementDisplayed(mobileCarousel)) {
      addGlide(mobileCarousel)
    }
  }
})
