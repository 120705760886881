document.addEventListener('turbolinks:load', () => {
  const menuTrigger = document.getElementById('menuTrigger')
  const menu = document.getElementById('menu')
  const menuHide = document.getElementById('menuHide')
  const menuItems = document.querySelectorAll('.o-menu__list-item')

  const showMenu = () => {
    menu.classList.remove('o-menu--is-closed')
    menu.classList.add('o-menu--is-displayed')
  }

  const hideMenu = () => {
    menu.classList.remove('o-menu--is-displayed')
    menu.classList.add('o-menu--is-closed')
  }

  if (menuTrigger) {
    menuTrigger.addEventListener('click', showMenu)
    menuHide.addEventListener('click', hideMenu)
  }

  if (menuItems) {
    menuItems.forEach(anchor => {
      anchor.addEventListener('click', hideMenu)
    })
  }
})

